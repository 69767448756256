import {Services} from "../components/publishers/Services";
import {Footer} from "../components/Footer";

import "./Home.scss"
import {Partners} from "../components/home/Partners";
import React, {RefObject, useEffect, useRef} from "react";
import {WorkTogether} from "../components/home/WorkTogether";
import {HomeHero} from "../components/publishers/HomeHero";
import {Demos} from "../components/home/Demos";

export const Home = () => {
    const services = [
        { image: 'project-management.jpeg', title: "Project Management", text:"Shepherding projects from start to finish with clients and partners.", tags: ["Account services", "Client management", "Timeline development", "Billing"] },
        { image: 'strategy.jpeg', title: "Strategy", text:"Developing the right strategy to build an audio-first campaign, even from existing creative.", tags: ["Strategy sessions", "Client workshops", "Industry trends", "Creative audits"] },
        { image: 'scripting.jpeg', title: "Scripting", text:"Writing clean and interesting copy with a complete understanding of the audio landscape.", tags: ["winnebago:Full scripting", "AI-assisted scripting", "liquidiv:Prompts for host read", "Proofreading", "Non-English scripting + translation"] },
        { image: 'voicing.jpeg', title: "Voicing", text:"Casting unique, talented actors—or working with existing talent—to ensure perfect performances.", tags: ["brooks:Roster of 250+ voice actors ", "transporttopics:Synthetic voicing", "Native speaking actors in 10 languages", "Live recording sessions"] },
        { image: 'production2.jpeg', title: "Production", text:"Producing custom or sourced music, sound design, and vocals to industry best-in-class standards.", tags: ["winnebago:Sound design", "Music curation", "Music composition", "salesforce:Mixing/mastering", "aarp:Animation"] },
        { image: 'ditribution.jpeg', title: "Distribution", text:"Distributing ads to their final placements, on our network or yours.", tags: ["In-Network distribution", "API connectivity", "Client handoff"] },
    ]

    const refs = new Map<string, RefObject<HTMLDivElement>>([
        ["salesForce", useRef(null)],
        ["winnebago", useRef(null)],
        ["brooks", useRef(null)],
        ["liquidiv", useRef(null)],
        ["transporttopics", useRef(null)],
        ["aarp", useRef(null)],
        ["work", useRef(null)]
    ])

    useEffect(() => {
        const r = refs.get("work")
        if (window.location.pathname === '/work-together' && r?.current)
            r.current.scrollIntoView()
    }, [refs.get("work")]);

    function goto(target: string) {
        const ref = refs.get(target)
        if (ref && ref.current) {
            ref.current.scrollIntoView({behavior: "smooth"})
        }
    }

    return <div className="advertisers">
        <HomeHero/>
        <Partners/>
        <Services services={services} button={"Work together"} goto={goto}/>
        <Demos refs={refs}/>
        <WorkTogether refs={refs}/>
        <Footer/>
    </div>
}
