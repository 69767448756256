import "./Row.scss"

type Podcast = { image: string; name: string; brand: string }

export const Row = (props: { podcasts: Podcast[] }) => {
    return <div className="podcasts-row">
        {props.podcasts.map((p, j) => <div className="podcast" key={"podcast" + j}>
            <div className="image">
                <img src={"../../podcasts/" + p.image} alt="Podcast"/>
            </div>
            <div className="name captions">{p.name}</div>
            <div className="brand">{p.brand}</div>
        </div>)}
    </div>
}
