import "./Services.scss"
import {S} from "../../assets/Strings";
import React from "react";
import {Service} from "../services/Service";

interface ServicesProps {
    button:string,
    services: Array<{
        title: string
        text : string
        tags: Array<string>
        number?: string
        fact?: string
        image: string
    }>
    goto?: string | ((target:string) => void)
}

const colors : Array<"pink"|"blue"|"yellow">= ["pink", "blue", "yellow"]

export const Services = (props: ServicesProps) => {
    return <div className="services">
        <div className="title h2">{S.services.title}</div>
        <div className="list">
            {props.services.map((s, i) => <Service key={`service-${i}`} color={colors[i%colors.length]} freeze={i === 0} opacity={i < props.services.length-1} button={props.button} goto={props.goto} {...s} />)}
        </div>
    </div>
}