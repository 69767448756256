import "./MoreArrow.scss"
import {useEffect, useRef, useState} from "react";
import {ReactComponent as Arrow} from "../assets/arrow down.svg"

export const MoreArrow = () => {
    const arrow = useRef<HTMLDivElement>(null)
    const [opacity, setOpacity] = useState(1)

    function handleScroll() {
        if (!arrow.current)
            return
        setOpacity(Math.max(0, 1 - window.scrollY / arrow.current.getBoundingClientRect().top))
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return () => window.removeEventListener("scroll", handleScroll)
    }, [])

    return <div className="more-arrow" style={{opacity: opacity}} ref={arrow}><Arrow/></div>
}