import "./PublishersHero.scss"
import {Button} from "../Button"
import {CSSProperties} from "react";
import {chunk} from "../../utils";
import {S} from "../../assets/Strings";
import {MoreArrow} from "../MoreArrow";

type HeroProps = {
    title?: string,
    thumbnails?: boolean,
}

export const PublishersHero = (props: HeroProps) => {
    const thumbnails = chunk(
        [
            "Accuweather.png",
            "CitiGPS.png",
            "DMNSportsDayInsider.png",
            "V2_DailyFinanceUpdates_Art.jpg",
            "DallasMorningNews.png",
            "EconWorldinBrief.png",
            "EngadgetNews.png",
            "FastCompanyDaily.png",
            "IGNDaily.png",
            "NYDailyNews.png",
            "NatureValley.png",
            "ShadowRealm.png",
            "SmallThingsOften.png",
            "TC-DailyCrunch.png",
            // "TC-Industry.png",
            // "TC-Startup.png",
            "ThisDayInHistory.png",
            "TodaysTipsfromAARP.png",
            "TransportTopics.png",
            "TurboTaxTips.png",

            "Accuweather.png",
            "CitiGPS.png",
            "DMNSportsDayInsider.png",
            "V2_DailyFinanceUpdates_Art.jpg",
            "DallasMorningNews.png",
            "EconWorldinBrief.png",
            "EngadgetNews.png",
            "FastCompanyDaily.png",
            "IGNDaily.png",
            "NYDailyNews.png",
            "NatureValley.png",
            "ShadowRealm.png",
        ]
    , 6)

    const speeds = ["10s", "16s", "12s", "14s", "18s"]

    const style = {
        "--opacity": props.thumbnails === false ? "0" : "0.2",
    } as CSSProperties

    return <div className="hero">
        <div className="thumbnails">
            {
                thumbnails.map((r, i) => <div className={"row row" + i} key={"row" + i} style={{"--speed": speeds[i]} as CSSProperties}>
                    {r.map(t => <div style={{background: "url(/podcasts/" + t + ") lightgray 50% / cover no-repeat", ...style}} className={"thumbnail"} key={"thumbnail" + t}/>)}
                    {r.map(t => <div style={{background: "url(/podcasts/" + t + ") lightgray 50% / cover no-repeat", ...style}} className={"thumbnail thumbnail2"} key={"thumbnail" + t + ".2"}/>)}
                </div>)
            }
        </div>
        <div className="heading">
            <div className="welcome">{S.publishers.welcome}</div>
            <div className="title h1">{props.title ?? S.publishers.title}</div>
            <div className="subtitle">{S.publishers.subtitle}</div>
            <Button text="Get Started" to="/work" color="var(--Primary-Black, #000A18)" bg="var(--Accent-Yellow, #FFEC51)" border="none"/>
        </div>
        <MoreArrow/>
    </div>
}