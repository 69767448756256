import {ReactComponent as Star} from "../../assets/star.svg"

import "./Testimonials.scss"
import {UIEvent, useState} from "react";

const testimonials = [
    {
        stars: "5",
        type: "publisher",
        text: "Been so busy and this helps me recenter and rebalance in the middle of my really crazy work days. LOVE!",
        name: "Take in the Outdoors",
        company: "Apple Podcasts",
        image: "NatureValley.png"
    },
    {
        stars: "5",
        type: "advertiser",
        text: "This is a great addition to my queue of podcasts I listen. This is short and sweet but informative news on gaming.",
        name: "IGN Daily Updates",
        company: "Apple Podcasts",
        image: "IGNDaily.png"
    },
    {
        stars: "5",
        type: "publisher",
        text: "This is just what I needed. I’m near 50 and learning things I never knew before. Thanks for the tips!! Keep it up!",
        name: "Today’s Tips from AARP",
        company: "Apple Podcasts",
        image: "TodaysTipsFromAARP.png"
    },
    {
        stars: "5",
        type: "advertiser",
        text: "I start each day by listening to The Dallas Morning News’ headline news. It provides just what I need to get rolling and directs me to the articles I want to read in more depth.",
        name: "Dallas Morning News",
        company: "Apple Podcasts",
        image: "DallasMorningNews.png"
    },
]

export const Testimonials = () => {
    const [current, setCurrent] = useState(0)

    function handleScroll(e : UIEvent) {
        const div = e.currentTarget
        const rect = div.getBoundingClientRect()
        let index = -1
        let width = -1

        for (let i = 0; i < div.children.length; i++) {
            const c = div.children[i].getBoundingClientRect()
            if ((c.left < rect.width)&&(c.right > 0)) {
                const w = Math.min(c.right, rect.width) - Math.max(0, c.left)
                if (w >= width) {
                    width = w
                    index = i
                }
            }
        }
        setCurrent(index)
    }

    return <div className="testimonials">
        <div className="title">Testimonials</div>
        <div className="carousel" onScroll={handleScroll}>
            {testimonials.map((t, i) => <div className={"card" + (i === current ? " current" : "")} key={"card" + i}>
                <div className="info">
                    <div className="rating"><Star/>{t.stars}</div>
                    <div className="type">{t.type}</div>
                </div>
                <div className="text">{t.text}</div>
                <div className="client">
                    <img src={"/podcasts/" + t.image} alt="podcast"/>
                    <div className="details">
                        <div className="name">{t.name}</div>
                        <div className="company">{t.company}</div>
                    </div>
                </div>
            </div>)}
        </div>
        <div className="scrollbar">
            {testimonials.map((t, i) => <div className={i === current ? "current" : "other"}/>)}
        </div>
    </div>
}