import "./Podcasts.scss"
import {chunk} from "../utils";
import {useState} from "react";
import {Footer} from "../components/Footer";
import {Row} from "../components/podcasts/Row";
import {S} from "../assets/Strings";

export const Podcasts = () => {
    const [shows, setShows] = useState(2)

    const featured = [
        {image: "Accuweather.png", name: "Accuweather Daily", brand: "Accuweather"},
        {image: "CitiGPS.png", name: "Citi GPS", brand: "Citi"},
        {image: "DallasMorningNews.png", name: "The Dallas Morning News", brand: "The Dallas Morning News"},
        {image: "DMNSportsDayInsider.png", name: "Sportsday Insider", brand: "The Dallas Morning News"},
    ]

    const more = chunk([
        {image: "EconWorldinBrief.png", name: "World in Brief", brand: "The Economist"},
        {image: "EngadgetNews.png", name: "Engadget News", brand: "Engadget"},
        {image: "FastCompanyDaily.png", name: "Fast Co Daily", brand: "Fast Company"},
        {image: "IGNDaily.png", name: "IGN Daily Update", brand: "IGN"},
        {image: "NatureValley.png", name: "Take In The Outdoors", brand: "Nature Valley"},
        {image: "NYDailyNews.png", name: "New York Daily News", brand: "New York Daily News"},
        {image: "ShadowRealm.png", name: "Shadow Realm", brand: "SpokenLayer"},
        {image: "SmallThingsOften.png", name: "Small Things Often", brand: "The Gottman Institute"},
        {image: "V2_DailyFinanceUpdates_Art.jpg", name: "Stock Market Variety", brand: "SpokenLayer"},
        {image: "TC-DailyCrunch.png", name: "Daily Crunch", brand: "TechCrunch"},
        {image: "TC-Industry.png", name: "TechCrunch Industry News", brand: "TechCrunch"},
        {image: "TC-Startup.png", name: "TechCrunch Startup News", brand: "TechCrunch"},
        {image: "ThisDayInHistory.png", name: "This Day in History", brand: "The History Channel"},
        {image: "TodaysTipsfromAARP.png", name: "Today’s Tips", brand: "AARP"},
        {image: "TransportTopics.png", name: "Transport Topics", brand: "Transport Topics"},
        {image: "TurboTaxTips.png", name: "Turbotax Tips", brand: "Turbotax"},
    ], 4)

    return <div className="podcasts">
        <div className="hero">
            <div className="title h1">{S.podcasts.title}</div>
            <div className="subtitle">{S.podcasts.subtitle}</div>
        </div>
        <div className="featured h2">Featured</div>
        <Row podcasts={featured}/>
        <div className="more h2">More Podcasts</div>
        {more.slice(0, shows).map((r, i) => <Row podcasts={r} key={"row" + i} />)}
        { shows < more.length &&
            <div className="show-more" onClick={() =>{setShows(shows+1)}}>more shows</div>
        }
        <Footer/>
    </div>
}