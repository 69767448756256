import "./WorkTogether.scss"
import {Button} from "../Button";
import {ReactComponent as Call} from "../../assets/call.svg"
import {ReactComponent as Trophy} from "../../assets/trophy.svg"
import {ReactComponent as Graph} from "../../assets/graph.svg"
import {RefObject, useState} from "react";
import axios from "axios";

interface WorkPreps {
    refs: Map<string, RefObject<HTMLDivElement>>
}

export const WorkTogether = (props: WorkPreps) => {
    const [leftHover, setLeftHover] = useState(false)
    const [rightHover, setRightHover] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [email, setEmail] = useState("")
    const [error, setError] = useState(false)

    function submit(){
        if (!email.trim()) {
            setError(true)
        } else
            axios.post("/a/mailing", {email}).then(() => setSubmitted(true))
    }

    return <div className="workTogether" ref={props.refs.get("work")}>
        <div className="title h2">Work Together</div>
        <div className="container">
            <div className="left" onMouseEnter={() => setLeftHover(true)} onMouseLeave={() => setLeftHover(false)}>
                <div className="header h3">Ready to become a partner?</div>
                <div className="subheader">Kickstart your successful campaign in 3 easy steps:</div>
                <div className="item">
                    <div className="icon"><Call/></div>
                    <div className="texts">
                        <div className="captions">Get in Touch</div>
                        <div className="bottom">Book a meeting with us, and we will reach out to learn more about your business.</div>
                    </div>
                </div>
                <div className="item">
                    <div className="icon"><Trophy/></div>
                    <div className="texts">
                        <div className="captions">Launch Campaign</div>
                        <div className="bottom">Our experts will collaborate with you to execute data-driven strategies.</div>
                    </div>
                </div>
                <div className="item">
                    <div className="icon"><Graph/></div>
                    <div className="texts">
                        <div className="captions">Get results</div>
                        <div className="bottom">Our experts will collaborate with you to execute data-driven strategies.</div>
                    </div>
                </div>
                <Button text="Schedule meeting"
                        bg="none" color={leftHover ? "var(--Primary-Black, #000A18)" : "var(--White, #F5FBFC)"}
                        border={leftHover ? "2px solid var(--Primary-Black, #000A18)" : "2px solid var(--White, #F5FBFC)"}
                        bgHover={leftHover ? "var(--Primary-Black, #000A18)" : "var(--White, #F5FBFC)"}
                        colorHover={leftHover ? "var(--White, #F5FBFC)" : "var(--Primary-Black, #000A18)"}/>
            </div>
            {submitted ? <div className="right submitted">
                <div className="header h3">You are All Set!</div>
                <div className="subheader">Our team will contact you within 1-2 business days.</div>
            </div> : <div className="right" onMouseEnter={() => setRightHover(true)}
                          onMouseLeave={() => setRightHover(false)}>
                <div className="header h3">Let’s keep in touch.</div>
                <div className="subheader">Sign up for updates on SpokenLayer and the audio world at large. Who knows, maybe we’ll work together soon.</div>
                <div className="form">
                    <input type="text" className="email" placeholder="Your email" value={email} onChange={e => {
                        setEmail(e.target.value)
                        setError(false)
                    }}/>
                    <Button text="Submit"
                            bg="none" color={rightHover ? "var(--Primary-Black, #000A18)" : "var(--White, #F5FBFC)"}
                            border={rightHover ? "2px solid var(--Primary-Black, #000A18)" : "2px solid var(--White, #F5FBFC)"}
                            bgHover={rightHover ? "var(--Primary-Black, #000A18)" : "var(--White, #F5FBFC)"}
                            colorHover={rightHover ? "var(--White, #F5FBFC)" : "var(--Primary-Black, #000A18)"}
                            onClick={submit}/>
                </div>
                {error && <div className='error paragraph2'>Provide your email to submit the form</div>}
            </div>}
        </div>
    </div>
}