import {ReactComponent as Logo} from "../assets/logo.svg"
import {ReactComponent as More} from "../assets/more.svg"

import "./Navbar.scss"
import {Button} from "./Button";
import {Link, NavLink} from "react-router-dom";
import {useState} from "react";

export const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false)
    function scrollToTop() {
        window.scrollTo({top: 0, behavior: 'smooth'})
        setMenuOpen(false)
    }

    return <div className={"navbar" + (menuOpen ? " open" : "")}>
        <NavLink to="/" onClick={scrollToTop}><Logo className="logo"/></NavLink>
        <div className="options">
            <NavLink to="/publishers" className="link" onClick={scrollToTop}>for Publishers</NavLink>
            {/*<NavLink to="/about" className="link" onClick={scrollToTop}>About</NavLink>*/}
            <NavLink to="/podcasts" className="link" onClick={scrollToTop}>Podcasts</NavLink>
            {/*<NavLink to="blog" className="link" onClick={scrollToTop}>Blog</NavLink>*/}
            <Button text="Get Started" to="/work" color="var(--Primary-Black, #000A18)" bg="var(--Accent-Yellow, #FFEC51)" border="none"/>
        </div>
        <div className="mobile-options">
            <Link to="/work" className="get-started">Get Started</Link>
            <div className="menu" onClick={() => setMenuOpen(!menuOpen)}><More/></div>
        </div>
        <div className="mobile-menu">
            <NavLink to="/publishers" className="link" onClick={scrollToTop}>for Publishers</NavLink>
            {/*<NavLink to="/about" className="link" onClick={scrollToTop}>About</Link>*/}
            <NavLink to="/podcasts" className="link" onClick={scrollToTop}>Podcasts</NavLink>
            {/*<NavLink to="blog" className="link" onClick={scrollToTop}>Blog</Link>*/}
            <div className="message-title">Message us</div>
            <a className="email" href="mailto:info@spokenlayer.com" target="_blank" rel='noreferrer'>info@spokenlayer.com</a>
            <div className="copyright">© SpokenLayer</div>
            <div className="address">4 E 27th Street, #20015<br/>New York, NY 10001<br/>(212) 655-0555</div>

        </div>
    </div>
}