import "./Work.scss"
import {Button} from "../components/Button"
import {ReactComponent as CheckMark} from "../assets/CheckMark.svg"
import {useState} from "react"
import {Footer} from "../components/Footer"
import axios from "axios"

export function Work() {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [hasPodcast, setHasPodcast] = useState(false)
    const [description, setDescription] = useState("")
    const [submitted, setSubmitted] = useState(false)

    async function submit() {
        axios.post("/a/contact", {name,email,phone,podcast: hasPodcast, description})
            .then(() => setSubmitted(true))
    }

    return <div className="work">
        <div className="hero">
            <div className="title h1">Fill Out free form and let’s work together</div>
            <div className="subtitle paragraph1">We deliver custom branded content and sponsorships on a new and different platform. And with our high-touch service model, you get a dedicated account manager who is with you from start to finish. SpokenLayer is where cutting-edge audio meets bespoke content marketing.</div>
        </div>
        {submitted ? <div className="all-set">
            <div className="title h2">You are All set!</div>
            <div className="subtitle">Our team will contact you within 1-2 business days.</div>
        </div> : <div className="work-form">
            <div className="form-header h2">Your Info</div>
            <div className="name-label">Full Name</div><input className="name-input" type="text" placeholder="Alex Green" value={name} onChange={e => setName(e.target.value)}/>
            <div className="details">
                <div className="email">
                    <div className="email-label">Email</div>
                    <input className="email-input" type="text" placeholder="alexgreen@gmail.com" value={email} onChange={e => setEmail(e.target.value)}/>
                </div>
                <div className="phone">
                    <div className="phone-label">Phone</div>
                    <input className="phone-input" type="text" placeholder="+1 (123) - 456 - 7890" value={phone} onChange={e => setPhone(e.target.value)}/>
                </div>
            </div>
            <div className={"have-podcast" + (hasPodcast ? " checked" : "")} onClick={()=>setHasPodcast(!hasPodcast)}><CheckMark/>I have a podcast</div>
            <div className="describe h2">Describe Your Project</div>
            <textarea className="description" placeholder="Describe your project and we will get back to you within 1-2 business days." value={description} onChange={e => setDescription(e.target.value)}/>
            <Button text="Submit" bg="none" color="var(--Primary-Black, #000A18)" border="2px solid var(--Primary-Black, #000A18)" bgHover="var(--Primary-Black, #000A18)" colorHover="var(--White, #F5FBFC)"  onClick={submit}/>
        </div>}
        <Footer/>

    </div>
}