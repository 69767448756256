import "./CTA.scss"
import {Button} from "../Button";

export const CTA = () => {
    return <div className="cta">
        <div className="left">
            <div className="title">Become An Advertiser</div>
            <div className="subtitle">Want to reach out to the tens of thousands of listeners who just heard that fun history fact? Or are locked into the latest tech news? We’ve got you covered with those audiences, and way more.</div>
        </div>
        <Button text="Become advertiser" bg="none" color="var(--White, #F5FBFC)" border="2px solid var(--White, #F5FBFC)" bgHover="var(--White, #F5FBFC)" to='/work-together'/>
    </div>
}