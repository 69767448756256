import "./Service.scss"

import {Button} from "../Button"
import {useEffect, useRef, useState} from "react"
import {ReactComponent as ArrowRight} from "../../assets/arrow-right.svg"
import {useNavigate} from "react-router-dom";

interface ServiceProps {
    color : "pink" | "blue" | "yellow"
    title: string
    text : string
    tags: Array<string>
    number?: string
    fact?: string
    image: string
    freeze: boolean
    opacity: boolean
    button: string

    goto?: string | ((target:string) => void)
}

export function Service(props: ServiceProps) {
    const ref = useRef<HTMLDivElement>(null)
    const [offset, setOffset] = useState(0)
    const [opacity, setOpacity] = useState(1)
    const navigate = useNavigate()

    function handleScroll(){
        if (!ref.current)
            return
        const rect = ref.current.getBoundingClientRect()
        const origY = rect.top + offset - window.innerHeight
        if (origY < 0) {
            if (!props.freeze)
                setOffset(Math.max(-1.1*ref.current.clientHeight, origY/1.75))
            if (props.opacity)
                setOpacity(Math.min(1, Math.max(0.2, 4+origY/ref.current.clientHeight*1.75)))
        } else {
            setOffset(0)
        }
    }

    useEffect(() => {
        handleScroll()
        window.addEventListener('scroll', handleScroll, {passive: true})
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, []);

    function go(target: string) {
        if (typeof (props.goto) == 'function') {
            props.goto(target)
        } else if (typeof (props.goto) == 'string') {
            navigate(props.goto)
        }
    }

    return <div className={`service ${props.color}`} ref={ref} style={{marginTop: offset, opacity: opacity}}>
        <div className="details">
            <div className="name h3">{props.title}</div>
            <div className="columns">
                <div className="text">{props.text}</div>
                <div className="tags">{props.tags.map(t => {
                    const col = t.indexOf(":")
                    if (col >= 0)
                        return <div className="tag link" onClick={() => go(t.substring(0, col))}>
                            <span className="check-out">Checkout this ad</span>
                            <ArrowRight/>
                            <span>{t.substring(col+1)}</span>
                        </div>
                    else
                        return <div className="tag">{t}</div>
                })}</div>
            </div>
            <Button className="start-project" text={props.button} bg="none" colorHover="var(--White, #F5FBFC)"
                    bgHover="var(--Primary-Black, #000A18)" onClick={() => go("work")}/>
        </div>
        <div className="image" style={{background: `url(/services/${props.image}) lightgray 50% / cover no-repeat`}}>
            {/*<div className="number h1">{props.number}</div>*/}
            {/*<div className="description captions">{props.fact}</div>*/}
        </div>
    </div>
}