import {ReactComponent as Location} from "../assets/location icon.svg"
import {ReactComponent as Email} from "../assets/email icon.svg"
import {ReactComponent as Logo} from "../assets/logo-small.svg"

import "./Footer.scss"
import {Button} from "./Button";

export const Footer = () => {
    return <div className="footer">
        <div className="contact-us">
            <div className="title h3">Contact Us</div>
            <div className="address">
                <Location/>
                <div className="text">
                    4 E 27th Street, #20015<br/>
                    New York, NY 10001<br/>
                    (212) 655-0555
                </div>
            </div>
            <div className="email">
                <Email/>
                <div className="text">info@spokenlayer.com</div>
            </div>
            <div className="form">
                <input type="text" className="email" placeholder="Your email"/>
                <textarea className="message" placeholder="Brief message"/>
            </div>
            <Button text="Submit" bg="none" color="var(--Primary-Black, #000A18)" border="2px solid var(--Primary-Black, #000A18)" bgHover="var(--Primary-Black, #000A18)" colorHover="var(--White, #F5FBFC)"/>
        </div>
        <div className="info">
            <div className="menu">
                <div className="column">
                    <div className="title captions">Services</div>
                    <div className="items">
                        <div className="item">Production</div>
                        <div className="item">Monetization</div>
                        <div className="item">Audience Development</div>
                        <div className="item">for Advertisers 1</div>
                        <div className="item">for Advertisers 2</div>
                        <div className="item">for Advertisers 3</div>
                    </div>
                </div>
                <div className="column">
                    <div className="title captions">Media</div>
                    <div className="items">
                        <div className="item">Podcasts</div>
                        {/*<div className="item">Blog</div>*/}
                    </div>
                </div>
                <div className="column">
                    <div className="title captions">About</div>
                    <div className="items">
                        <div className="item">About</div>
                        <div className="item">Latest Awards</div>
                        <div className="item">Our Team</div>
                        <div className="item">FAQs</div>
                    </div>
                </div>
                <div className="column">
                    <div className="title captions">Careers</div>
                    <div className="items">
                        <div className="item">Careers</div>
                        <div className="item">Job Posting</div>
                        <div className="item">Voice Artists</div>
                    </div>
                </div>
                <div className="column">
                    <div className="title captions">Work with us</div>
                    <div className="items">
                        <div className="item">for Podcasters</div>
                        <div className="item">for Advertisers</div>
                    </div>
                </div>
            </div>
            <div className="social">
                <div className="title captions">Our Social</div>
                <div className="links">
                    <div className="link">LinkedIn</div>
                    <div className="link">X</div>
                    <div className="link">Instagram</div>
                    <div className="link">Facebook</div>
                    <div className="link">Newsletters</div>
                </div>
            </div>
            <div className="logo">
                <Logo/>
            </div>
        </div>
    </div>
}